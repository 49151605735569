import classNames from 'classnames'
import React from 'react'
import SVG from '../../../components/svg'

interface SubtitlesEditorToolsProps {
    toggleDraggable(): void;
    toggleView(): void;
    hideDeletedWords: boolean;
    draggable: boolean;
    disabled: boolean;
}

export default function SubtitlesEditorTools({ toggleDraggable, draggable, toggleView, hideDeletedWords, disabled }: SubtitlesEditorToolsProps & {}) {

    const hideDeletedWordsDisabled: boolean = draggable || disabled

    const hideDeletedWordsClassNames = classNames('editor--content--tools--action', { 'selected': hideDeletedWords, 'disabled': hideDeletedWordsDisabled })
    const reorderClassNames = classNames('editor--content--tools--action', { 'selected': draggable, 'disabled': disabled })

    // TODO remove visibility to enable feature
    return (<div className="editor--content--tools" style={{visibility: 'hidden'}}>
        <a className={hideDeletedWordsClassNames} onClick={() => !hideDeletedWordsDisabled && toggleView()}>
            <SVG name={hideDeletedWords ? 'unmask' : 'mask'} />
        </a>
        <a className={reorderClassNames} data-cy="scenes-reorder-btn" onClick={() => !disabled && toggleDraggable()}>
            <SVG name='re-order' viewBox="0 0 33 16" />
        </a>
    </div>)
}